<template>
  <div class="modal-container" :class="{ show }">
    <div class="modal forgot-password">
      <h2>Mot de passe oublié</h2>
      <font-awesome-icon :icon="['fas', 'times']" class="close" @click="hideModal" />
      <form @submit.prevent="submit">
        <label
          for="forgot-password-email"
          class="form"
        >Email</label>
        <input
          v-model="email"
          class="form"
          type="email"
          id="forgot-password-email"
          placeholder="john.doe@example.com"
          @change="error = null"
        />
        <span class="form">{{ error }}</span>
        
        <button
          class="form full"
          type="submit"
        >Envoyer</button>
        <button
          class="form full close"
          @click="hideModal"
          @click.prevent
        >Fermer</button>
      </form>
    </div>
  </div>
  
</template>

<script>
import { auth } from '@/firebase'
// import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ForgotPassword',
  props: {
    emailFromLogin: {
      default: null,
    },
  },
  data() {
    return {
      email: null,
      error: null,
      show: false,
    }
  },
  computed: {
    
  },
  methods: {
    showModal() {
      this.email = this.emailFromLogin
      this.error = null
      this.show = true
    },
    hideModal() {
      this.show = false
    },
    submit() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regex.test(this.email)) {
        auth.sendPasswordResetEmail(this.email)
          .then(() => {
            this.hideModal()
            this.$toasted.success('Un email de réinitialisation du mot de passe vous a été envoyé', {
              duration: 5000,
              position: "bottom-center",
            })
          })
          .catch((error) => {
            if (error.code === 'auth/user-not-found') {
              this.error = "Aucun utilisateur avec cette adresse email."
            } else {
              this.$toasted.error('Une erreur inconnue est survenue', {
                duration: 5000,
                position: "bottom-center",
              })
            }
          });
      } else {
        this.error = "L'email est invalide."
      }
    },
  },
}
</script>

<style lang="scss" scoped>
svg.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.modal.forgot-password {
  min-width: 300px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    margin: 10px 0 0;

    &.close {
      margin: 10px 0 0 !important;
      background-color: white;
      color: #3490dc;
      border: 1px solid #3490dc;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
}
</style>