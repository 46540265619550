<template>
  <div class="login">
    <img v-if="getLoginBackground" :src="getLoginBackground">
    <div class="container">
      <div class="nav">
        <span class="clickable" :class="{ active: show === 'login'}" @click="show = 'login'; inputsError.email = null">Connexion</span>
        <span class="middle">ou</span>
        <span class="clickable" :class="{ active: show === 'signup'}" @click="show = 'signup'; inputsError.email = null">Inscription</span>
      </div>

      <form @submit.prevent="loginForm" v-if="show === 'login'">
        <div class="group">
          <label for="login-email">Email</label>
          <input
            type="email"
            id="login-email"
            placeholder="john.doe@example.com"
            v-model.trim="inputs.email"
            @input="inputsError.email = null"
          />
          <span class="form">{{ inputsError.email }}</span>
        </div>
        <div class="group">
          <label for="login-password">Mot de passe</label>
          <input
            type="password"
            placeholder="**************"
            v-model.trim="inputs.login.password"
            @input="inputsError.login.password = null"
          />
          <span class="form">{{ inputsError.login.password }}</span>
          <span class="forgot-password" @click="forgotPassword">Mot de passe oublié</span>
        </div>
        <button type="submit">Connexion</button>
      </form>

      <form @submit="signupForm" v-else>
        <div class="group">
          <label for="firstname">Prénom</label>
          <input
            type="text"
            id="firstname"
            placeholder="john"
            v-model.trim="inputs.signup.firstname"
            @input="inputsError.signup.firstname = null"
          />
          <span class="form">{{ inputsError.signup.firstname }}</span>
        </div>
        <div class="group">
          <label for="lastname">Nom</label>
          <input
            type="text"
            id="lastname"
            placeholder="doe"
            v-model.trim="inputs.signup.lastname"
            @input="inputsError.signup.lastname = null"
          />
          <span class="form">{{ inputsError.signup.lastname }}</span>
        </div>
        <div class="group">
          <label for="signup-email">Email</label>
          <input
            type="email"
            id="signup-email"
            placeholder="john.doe@example.com"
            v-model.trim="inputs.email"
            @input="inputsError.email = null"
          />
          <span class="form">{{ inputsError.email }}</span>
        </div>
        <div class="group">
          <label for="signup-password">Mot de passe (6 caractères minimum)</label>
          <input
            type="password"
            id="signup-password"
            placeholder="**************"
            v-model.trim="inputs.signup.password"
            @input="inputsError.signup.password = null"
          />
          <span class="form">{{ inputsError.signup.password }}</span>
        </div>
        <div class="group">
          <label for="password-verif">Verification mot de passe</label>
          <input
            type="password"
            id="password-verif"
            placeholder="**************"
            v-model.trim="inputs.signup.passwordVerif"
            @input="inputsError.signup.passwordVerif = null"
          />
          <span class="form">{{ inputsError.signup.passwordVerif }}</span>
        </div>
        <button type="submit">Inscription</button>
      </form>
    </div>
    <forgot-password ref="forgot-password" :emailFromLogin="inputs.email" />
  </div>
</template>

<script>
import ForgotPassword from '@/components/ForgotPassword'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
    ForgotPassword,
  },
  data() {
    return {
      inputs: {
        email: null,
        login: {
          password: null,
        },
        signup: {
          firstname: null,
          lastname: null,
          password: null,
          passwordVerif: null,
        },
      },
      regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      inputsError: {
        email: null,
        login: {
          password: null
        },
        signup: {
          firstname: null,
          lastname: null,
          password: null,
          passwordVerif: null,
        },
      },
      show: 'login'
    }
  },
  computed: {
    ...mapGetters('settings', ['getLoginBackground'])
  },
  methods: {
    ...mapActions('user', ['login', 'signup']),

    loginForm(e) {
      e.preventDefault()

      if (this.regex.test(this.inputs.email)) {
        this.login({
          email: this.inputs.email,
          password: this.inputs.login.password,
        }).then(response => {
          if (!response) return
          if (response.code === 'auth/user-not-found') {
            this.inputs.login.password = null
            this.inputs.email = null
            this.$toasted.error('Aucun utilisateur ne correspond aux informations renseignées', {
              duration: 5000,
              position: 'bottom-center',
            })
          } else if (response.code === 'auth/wrong-password') {
            this.inputs.login.password = null
            this.inputsError.login.password = 'Mot de passe incorrect'
          } else if (response.code === 'auth/too-many-requests') {
            this.inputs.login.password = null
            this.inputs.email = null
            this.$toasted.error("L'accès à ce compte a été restreint temporairement suite à de trop nombreux essais de connexion.\nVous pouvez récuperer l'accès en cliquant sur mot de passe oublié ou veuillez réessayer plus tard.", {
              duration: 10000,
              position: 'bottom-center',
            })
          } else {
            this.inputs.login.password = null
            this.inputs.email = null
            this.$toasted.error('Une erreur inconnue est survenue', {
              duration: 5000,
              position: 'bottom-center',
            })
          }
        })
      } else {
        this.inputsError.email = "L'email est invalide."
      }

    },

    signupForm(e) {
      e.preventDefault()

      let error = false

      if (!this.inputs.signup.firstname || this.inputs.signup.firstname.length < 1) {
        this.inputsError.signup.firstname = 'Ce champ est obligatoire'
        error = true
      }
      if (!this.inputs.signup.lastname || this.inputs.signup.lastname.length < 1) {
        this.inputsError.signup.lastname = 'Ce champ est obligatoire'
        error = true
      }
      if (!this.regex.test(this.inputs.email)) {
        this.inputsError.email = "L'email est invalide."
        error = true
      }
      if (!this.inputs.signup.password || this.inputs.signup.password.length < 6) {
        this.inputsError.signup.password = 'Le mot de passe doit faire 6 caractères minimum'
        error = true
      }
      if (this.inputs.signup.password !== this.inputs.signup.passwordVerif) {
        this.inputsError.signup.passwordVerif = 'Les mots de passes ne sont pas identiques'
        error = true
      }

      if (error)
        return

      this.signup({
        firstname: this.inputs.signup.firstname,
        lastname: this.inputs.signup.lastname,
        email: this.inputs.email,
        password: this.inputs.signup.password,
      }).then(response => {
        if (!response) return
        if (response.code === 'auth/weak-password') {
          this.inputsError.signup.password = 'Le mot de passe doit faire 6 caractères minimum'
        } else if (response.code === 'auth/email-already-in-use') {
          this.inputsError.email = 'Cette adresse email est déjà utilisée'
        } else {
          this.$toasted.error('Une erreur inconnue est survenue', {
            duration: 5000,
            position: 'bottom-center',
          })
        }
      })
    },

    forgotPassword() {
      this.$refs['forgot-password'].showModal()
    }
  },
}
</script>

<style lang="scss" scoped>
div.login {
  width: 100vw;
  display: flex;

  img {
    display: none;
    width: 50%;
    height: 100vh;
    object-fit: cover;

    @media screen and (min-width: 769px) {
      display: block;
    }
  }

  div.container {
    width: 100%;
    min-height: 100vh;
    padding: 50px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #283447, #1b2330);

    @media screen and (min-width: 769px) {
      width: 50%;
    }

    div.nav {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;

      span {
        font-size: 22px;
        color: #8d949e;
        border-bottom: 2px solid transparent;

        &.clickable {
          cursor: pointer;
        }

        &.middle {
          font-size: 16px;
          margin: 0 10px;
          font-weight: 300;
        }

        &.active {
          color: white;
          border-bottom-color: #2da4d0;
        }
      }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;

      div.group {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        label {
          font-size: 14px;
          color: #8d949e;
          font-weight: 300;
        }

        input {
          padding: 10px 0 7px;
          background: transparent;
          border: solid #8d949e;
          border-width: 0 0 2px 0;
          font-size: 16px;
          color: white;

          &:focus {
            outline: 0;
            box-shadow: 0 0 0 3px rgb(52 144 220 / 50%);
            border-color: #3490dc;
          }
        }

        span.forgot-password {
          font-size: 14px;
          margin-top: 10px;
          color: #3490dc;
          cursor: pointer;
        }
      }

      button {
        width: 100%;
        line-height: 40px;
        background-color: #2da4d0;
        border: 0;
        border-radius: 5px;
        font-size: 16px;
        color: white;
        font-weight: 300;
        margin-top: 50px;
      }

    }
  }
}
</style>